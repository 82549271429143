import { uomForEmpDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useChargeDetailVM } from "presentation/hook/ChargeDetailMaintenance/useChargeDetailVM";
import { ChargeDetailModel } from "presentation/model/ChargeDetailMaintenance/ChargeDetailModel";
import { InputDropdown } from "veronica-ui-component/dist/component/core";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback } from "react";

export const EmptyQtyAccordion = ({chargeDetailState}:{chargeDetailState:ChargeDetailModel}) => {
    const chargeBatchUpdate = chargeDetailState.chargeBatchUpdate
    const chargeDetailVM = useChargeDetailVM();
    const onNumberFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, fieldName:string) => {
        chargeDetailVM.onInputTextChange(event, fieldName, chargeBatchUpdate, 'chargeBatchUpdate');
    }, [chargeDetailVM, chargeBatchUpdate]);
    return (
        <>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Charge UOM'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.chargeUom}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'chargeUom', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={uomForEmpDropdownOption}/>
                </div>
                
                <div className='im-flex-row-item'>
                    <InputDropdown
                        label='Pool UOM'
                        width='150px'
                        inputType="freeText"
                        mode={"single"}
                        value={chargeBatchUpdate.poolUom}
                        onChange={(e) => chargeDetailVM.onDropdownChange(e, 'poolUom', chargeBatchUpdate, 'chargeBatchUpdate')}
                        options={uomForEmpDropdownOption}/>
                </div>
            </div>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <NumberInputComponent
                        label={`20'`}
                        width='150px'
                        fieldName={"empty20Qty"}
                        value={chargeBatchUpdate.empty20Qty || ''}
                        disabled={false}
                        decimalPlaces={2}
                        onChange={(e: any) =>onNumberFieldChange(e,'empty20Qty')}
                    />
                    {/*<InputField
                        label={`20'`}
                        width='150px'
                        type="number"
                        value={chargeBatchUpdate.empty20Qty || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'empty20Qty', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
                </div>
                <div className='im-flex-row-item'>
                    <NumberInputComponent
                        label={`40'`}
                        width='150px'
                        fieldName={"empty40Qty"}
                        value={chargeBatchUpdate.empty40Qty || ''}
                        disabled={false}
                        decimalPlaces={2}
                        onChange={(e: any) =>onNumberFieldChange(e,'empty40Qty')}
                    />
                    {/*<InputField
                        label={`40'`}
                        width='150px'
                        type="number"
                        value={chargeBatchUpdate.empty40Qty || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'empty40Qty', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
                </div>
            </div>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <NumberInputComponent
                        label={`45'`}
                        width='150px'
                        fieldName={"empty45Qty"}
                        value={chargeBatchUpdate.empty45Qty || ''}
                        disabled={false}
                        decimalPlaces={2}
                        onChange={(e: any) =>onNumberFieldChange(e,'empty45Qty')}
                    />
                    {/*<InputField
                        label={`45'`}
                        width='150px'
                        type="number"
                        value={chargeBatchUpdate.empty45Qty || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'empty45Qty', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
                </div>
                <div className='im-flex-row-item'>
                    <NumberInputComponent
                        label={`48'`}
                        width='150px'
                        fieldName={"empty48Qty"}
                        value={chargeBatchUpdate.empty48Qty || ''}
                        disabled={false}
                        decimalPlaces={2}
                        onChange={(e: any) =>onNumberFieldChange(e,'empty48Qty')}
                    />
                    {/*<InputField
                        label={`48'`}
                        width='150px'
                        type="number"
                        value={chargeBatchUpdate.empty48Qty || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'empty48Qty', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
                </div>
            </div>
            <div className={'im-flex-row'} style={{marginBottom:'24px'}}>
                <div className='im-flex-row-item'>
                    <NumberInputComponent
                        label={`53'`}
                        width='150px'
                        fieldName={"empty53Qty"}
                        value={chargeBatchUpdate.empty53Qty || ''}
                        disabled={false}
                        decimalPlaces={2}
                        onChange={(e: any) =>onNumberFieldChange(e,'empty53Qty')}
                    />
                    {/*<InputField
                        label={`53'`}
                        width='150px'
                        type="number"
                        value={chargeBatchUpdate.empty53Qty || ''}
                        onChange={(e: any) => chargeDetailVM.onInputTextChange(e, 'empty53Qty', chargeBatchUpdate, 'chargeBatchUpdate')}/>*/}
                </div>
            </div>
        </>
    )
}